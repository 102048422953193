<template>
  <div id="app" :class="{ 'view-app': !isMobile, 'view-app-mobile': isMobile }">
    <div class="app-in">
      <CompLoading v-if="globalLoading" />
      <keep-alive include="BlindBox">
        <router-view />
      </keep-alive>
      <div
        v-show="showScrollTopBtn"
        @click="scrollTopOnClick"
        class="scroll-top"></div>
      <div style="opacity: 0; width: 0; height: 0" id="footer"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { Blind_box_channel } from "@/network/api.js";
function func(event) {
  event.preventDefault();
}
export default {
  name: "",
  data() {
    return {
      showScrollTopBtn: false,
      touchMoveHandler: () => {},
    };
  },
  computed: {
    ...mapState(["isMobile", "globalLoading"]),
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", () => {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          const C_id = sessionStorage.getItem("C_id");
          console.log(4444444, C_id);
          if (!C_id) {
            this.$socket
              .Event((e) => {
                sessionStorage.setItem("C_id", e);
                console.log(555555555, sessionStorage.getItem("C_id"));
                Blind_box_channel(e).then((res) => {});
              })
              .Start();
          }
        }
      });
    });
  },
  created() {
    setTimeout(() => {
      this.setLoading(false);
    }, 3000);
    console.log("APP.vue文件加载2024-100801");
    this.$socket
      .Event((e) => {
        sessionStorage.setItem("C_id", e);
        Blind_box_channel(e).then((res) => {});
      })
      .Start();
    this.$socket.Add("CreateGroup", this.CreateGrounp);
    this.$socket.Add("joinGroup", this.JoinGroup);
    this.$socket.Add("startGroup", this.AddStartGroup);
    this.$socket.Add("endGroup", this.AddEndGroup);
    this.$socket.Add("start", this.AddRound);
    this.Init();
  },
  watch: {
    "$route"(newVal) {
      if (newVal.path === "/Home") {
        document.addEventListener("touchmove", func, {
          passive: false,
        });
      } else {
        document.removeEventListener("touchmove", func, {
          passive: false,
        });
      }
      this.Refresh();
      this.getGlobalInfo();
    },
  },

  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions(["Refresh", "getGlobalInfo", "GetKaiShiVsData"]),
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    CreateGrounp(data) {
      this.ChuangjianVsData(data);
    },
    JoinGroup(data) {
      this.JiaRuVsData(data);
    },
    AddStartGroup(data) {
      this.GetKaiShiVsData(data);
    },
    AddEndGroup(data) {
      this.GetJieShuVsData(data);
    },
    AddRound(data) {
      this.GetRoundNum(data);
    },
    ...mapActions([
      "ChuangjianVsData",
      "JiaRuVsData",
      "GetJieShuVsData",
      "GetKaiShiVsData",
      "GetRoundNum",
    ]),
    ...mapActions({
      MyInfo: "Refresh",
    }),
    Init() {
      this.MyInfo();
      window.addEventListener("scroll", () => {
        this.showScrollTopBtn = false;
        if (document.documentElement.scrollTop > 800) {
          this.showScrollTopBtn = true;
        }
      });
    },
    scrollTopOnClick() {
      const timeTop = setInterval(() => {
        let top = document.documentElement?.scrollTop || 0;
        document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 5);
    },
  },
};
</script>

<style lang="scss">
.app-in {
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
.view-app {
  // display: none;
  width: 58.05vh;
  height: 100vh;
  // overflow-y: auto;
  position: fixed;
  left: 50%;
  top: 0;
  // @include bgImg("battle-bg");
  transform: translateX(-50%);
}
.view-app-mobile {
  // display: none;
  width: 100%;
  height: 100%;
  // background: pink;
  // overflow-y: auto;
  position: relative;
  left: 0;
  top: 0;
  // &::after {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   z-index: -1;
  //   @include bgImg("battle-bg");
  // }
}
.el-drawer__body {
  background-color: #20222b !important;
}

.bg-main {
  background-image: linear-gradient(90deg, #ecb105, rgb(0 0 0 / 0%)),
    linear-gradient(#ec7205, rgb(0 0 0 / 0%));
}

@import "./styles/bootstrap.css";
@import "./styles/common.scss";
@import "./assets/fonts/FZZ.css";

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.lv1 {
  background: url(./assets/newImages/battle-award-bg-lv1.png) no-repeat center;
  background-size: 100% 100%;
}

.lv2 {
  background: url(./assets/newImages/battle-award-bg-lv2.png) no-repeat center;
  background-size: 100% 100%;
}

.lv3 {
  background: url(./assets/newImages/battle-award-bg-lv3.png) no-repeat center;
  background-size: 100% 100%;
}

.lv4 {
  background: url(./assets/newImages/battle-award-bg-lv4.png) no-repeat center;
  background-size: 100% 100%;
}

.lv5 {
  background: url(./assets/newImages/battle-award-bg-lv4.png) no-repeat center;
  background-size: 100% 100%;
}

.open-lv1 {
  background: url(./assets/images/NavHeader/LV1-new.png) no-repeat center;
  background-size: 100% 100%;
}

.open-lv2 {
  background: url(./assets/images/NavHeader/LV2-new.png) no-repeat center;
  background-size: 100% 100%;
}

.open-lv3 {
  background: url(./assets/images/NavHeader/LV3-new.png) no-repeat center;
  background-size: 100% 100%;
}

.open-lv4 {
  background: url(./assets/images/NavHeader/LV4-new.png) no-repeat center;
  background-size: 100% 100%;
}

.open-lv5 {
  background: url(./assets/images/NavHeader/LV4-new.png) no-repeat center;
  background-size: 100% 100%;
}

.el-slider {
  opacity: 1;
  position: absolute;
  width: 98%;
  height: 0.3rem;
  left: 0%;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 8;
}

.el-slider__bar {
  height: 0.3rem !important;
  background: url(assets/newImages/progress-bar-content.png) no-repeat !important;
  background-size: 100% 100%;
  background-position: 0.2rem 0.2rem;
}
.el-slider__runway {
  width: 102.5% !important;
  height: 0.3rem !important;
  margin: 0 !important;
  background: none !important;
}
.el-slider__button-wrapper {
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.07rem !important;
  height: 100% !important;
  top: 0rem !important;
  // background: red;
}
.el-slider__button {
  display: none;
  border-radius: 0 !important;
  width: 100% !important;
  height: 0.14rem !important;
  opacity: 0;
  padding-left: 0.3rem;
  margin-left: 0.2rem;
}
.el-tooltip__popper {
  display: none !important;
}

* {
  margin: 0;
  padding: 0;
  background: url(/iconfont.svg);
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.van-overlay {
  z-index: 999 !important;
  // background-color: rgba(0, 0, 0, 0.8) !important;
  overflow: hidden;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.Title-bg {
  width: 20rem;
  /*border: 1px solid #191b31;*/
  /*background-color: #131426;*/
  margin-left: -3rem;
  padding: 0.1rem;
}

.Title-title {
  position: relative;

  &::before {
    content: "";
    bottom: -0.2rem;
    left: 37.5%;
    position: absolute;
    width: 3rem;
    height: 50px;
    /*background-image: url("./assets/images/OpenBox/title.png");*/
    background-size: 100% 100%;
  }
}

.Title {
  text-align: center;

  p {
    display: inline-block;
    margin: 0 auto;
    font-size: 0.39rem;
    font-weight: 400;
    color: #cccccc;
    line-height: 1.66rem;
    text-transform: uppercase;
    /*text-shadow: 0 0 15px hsla(0, 0%, 100%, .5);*/
    padding: 20px 56px;
    // clip-path: polygon(13px 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 13px 100%, 0 50%);
    position: relative;
    font-family: "FZZ";
  }
}

@keyframes zhuan {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*单行省略*/
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

/*多行文本省略*/
.twice {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.scroll-top {
  position: fixed;
  right: 0.1rem;
  bottom: 1rem;
  width: 0.34rem;
  height: 0.34rem;
  background: url("./assets/newImages/mb-top.png") no-repeat;
  background-size: 100% 100%;
}
</style>
