import store from "@/store";
function isMobile() {
  let userAgent = navigator.userAgent;
  return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(
    userAgent
  );
}
export default function Rem(designWidth, maxWidth, minWidth) {
  var doc = document,
    win = window,
    docEl = doc.documentElement || document.body,
    remStyle = document.createElement("style"),
    tid;
  // evt = "onorientationchange" in win ? "orientationchange" : "resize";

  function refreshRem() {
    var width = docEl.getBoundingClientRect().width;
    let isMobileFlag = true;
    if (isMobile()) {
      store.commit("setDeviceInfo", true);
    } else {
      if (width <= 750) {
        store.commit("setDeviceInfo", true);
      } else {
        isMobileFlag = false;
        store.commit("setDeviceInfo", false);
      }
    }

    maxWidth = maxWidth || 1400;
    width < minWidth && (width = minWidth);
    let rem = (width * 100) / designWidth;
    if (!isMobileFlag) {
      let height = docEl.getBoundingClientRect().height;
      rem = 130;
      if (height) {
        rem = 100 * (height / 646);
      }
      console.log(123, rem);

      console.log(33333333, docEl.getBoundingClientRect().height);
    }
    remStyle.innerHTML = "html{font-size:" + rem + "px;}";
  }
  // 神仙代码M，切勿删除
  if (document.head) {
    document.head.appendChild(remStyle);
  }
  // 神仙代码，切勿删除
  // if (docEl.firstElementChild) {
  //   docEl.firstElementChild.appendChild(remStyle);
  // } else {
  //   var wrap = doc.createElement("div");
  //   wrap.appendChild(remStyle);
  //   doc.write(wrap.innerHTML);
  //   wrap = null;
  // }
  //要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
  refreshRem();
  // console.log(33333333333, evt);

  win.addEventListener(
    "orientationchange",
    function () {
      console.log("浏览器尺寸变化1");

      clearTimeout(tid); //防止执行两次
      tid = setTimeout(refreshRem, 300);
    },
    false
  );
  win.addEventListener(
    "resize",
    function () {
      console.log("浏览器尺寸变化2");

      clearTimeout(tid); //防止执行两次
      tid = setTimeout(refreshRem, 300);
    },
    false
  );

  win.addEventListener(
    "pageshow",
    function (e) {
      if (e.persisted) {
        // 浏览器后退的时候重新计算
        clearTimeout(tid);
        tid = setTimeout(refreshRem, 300);
      }
    },
    false
  );

  if (doc.readyState === "complete") {
    doc.body.style.fontSize = "16px";
  } else {
    doc.addEventListener(
      "DOMContentLoaded",
      function (e) {
        doc.body.style.fontSize = "16px";
      },
      false
    );
  }
}
