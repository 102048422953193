<template>
  <div class="comp-coin flex-c">
    <div :style="imgStyleSheet" class="coin-img flex-c">
      <img
        class="h100"
        :src="require(`../../assets/newImages/${imgName}.png`)"
        alt="" />
    </div>
    <div :style="textStyleSheet" v-if="num !== undefined" class="coin-num">
      {{ num }}
    </div>
    <div v-else class="coin-num">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CompCoin",
  data() {
    return {};
  },
  props: {
    num: {
      type: [Number, String],
      default: undefined,
    },
    styleList: {
      type: Array,
      default: () => [0.1, 0.1, 0.12, 0.05],
    },
    imgName: {
      type: String,
      default: "coin-d",
    },
  },
  computed: {
    imgStyleSheet() {
      const styleStr = `width:${this.styleList[0] || 0.1}rem;height:${
        this.styleList[1] || 0.1
      }rem;margin-right:${this.styleList[3] || 0.05}rem;`;
      return styleStr;
    },
    textStyleSheet() {
      const styleStr = `font-size:${this.styleList[2] || 0.12}rem;`;
      return styleStr;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.comp-coin {
  .coin-img {
    width: 0.1rem;
    height: 0.1rem;
    margin-right: 0.05rem;
    // @include bgImg("coin");
  }
  .coin-num {
    font-size: 0.12rem;
  }
}
</style>
