const mp3Start = new Audio(require("@/assets/audios/openb.mp3")); //开箱过程声音
const mp3End = new Audio(require("@/assets/audios/boom.mp3")); //开箱结束声音
export default {
  //设置用户信息
  User(state, u) {
    state.user = u;
  },
  // 设置设备信息
  setDeviceInfo(state, u) {
    state.isMobile = u;
  },
  setLoading(state, u) {
    state.globalLoading = u;
  },
  setGlobalInfo(state, u) {
    state.globalInfo = u;
  },
  ChangeAudioStatus(store, val) {
    store.soundStatus = val;
    if (val) {
      mp3Start.load();
      mp3End.load();
      store.mp3Start = mp3Start;
      store.mp3End = mp3End;
    } else {
      store.mp3Start = null;
      store.mp3End = null;
    }
  },

  GetIndividualsShow(state, val) {
    state.IndividualsShow = val;
  },

  //回合
  GetroundNum(state, val) {
    state.roundNum = val;
  },

  //开始
  GetVsKaiShi(state, val) {
    state.VsKaiShi = val;
  },

  //加入轮数信息
  RoundVsData(store, val) {
    console.log("触发RoundVsData");
    store.RoundVsData = val;
  },
  // 创建房间信息
  ChuangJianVsData(store, val) {
    console.log("触发ChuangjianVsData");
    store.ChuangjianVsData = val;
    console.log(6666, store.ChuangjianVsData);
  },
  //加入房间信息
  JiaRuVsData(store, val) {
    console.log("触发JiaRuVsData");
    store.JiaRuVsData = val;
  },

  //开始对战数据
  GetKaiShiVsData(state, val) {
    state.KaiShiVsData = val;
  },

  //结束对战数据
  GetJieShuVsData(state, val) {
    state.JieShuVsData = val;
  },

  //结束
  GetVsJieShu(state, val) {
    state.VsJieShu = val;
  },

  //注册弹框
  GetRegisterShow(state, val) {
    state.RegisterShow = val;
  },

  //登录弹框
  GetSignInShow(state, val) {
    state.SignInShow = val;
  },
  //高光时刻弹窗
  SetPopupHighLightShow(state, obj = {}) {
    state.popupHighLightShow = obj.val;
    state.popupHighLightId = obj.id;
  },
  // 保留tab数据
  SetTabIndex(state,index) {
    localStorage.setItem(state.tabIndex, index);
  },
  //退出登录
  Leave(state) {
    state.user.state = false;
    state.user = {};
    // window.localStorage.clear();
    // 需要保留的键名数组
    const keysToKeep = ["savedUsername", "savedPassword"];
    function clearLocalStorageExcept(keysToKeep) {
      // 保存需要保留的数据
      const savedData = {};
      // 遍历localStorage中的所有键值对
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (keysToKeep.includes(key)) {
          // 保留指定的键值对
          savedData[key] = localStorage.getItem(key);
        }
      }
      // 清空localStorage
      localStorage.clear();
      // 重新设置保留的数据
      Object.keys(savedData).forEach((key) => {
        localStorage.setItem(key, savedData[key]);
      });
    }
    // 调用函数
    clearLocalStorageExcept(keysToKeep);
    window.sessionStorage.clear();
  },
};
