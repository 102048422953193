import { PersonalInfo, Info } from "@/network/api";

export default {
  // 创建房间推送
  ChuangjianVsData(context, val) {
    console.log("创建房间", val);
    context.commit("ChuangJianVsData", val);
  },
  //加入房间推送
  JiaRuVsData(context, val) {
    console.log("加入房间11111", val);
    context.commit("JiaRuVsData", val);
  },

  //回合
  GetRoundNum(context, val) {
    console.log("回合", val);
    context.commit("RoundVsData", val);
  },

  //开始
  GetVsKaiShi(context, val) {
    console.log("开始", val);
    context.commit("GetVsKaiShi", val);
  },

  //结束
  GetVsJieShu(context, val) {
    console.log("结束", val);
    context.commit("GetVsJieShu", val);
  },

  //开始对战数据
  GetKaiShiVsData(context, val) {
    console.log("开始对战", val);
    context.commit("GetKaiShiVsData", val);
  },

  //结束对战数据
  GetJieShuVsData(context, val) {
    console.log("结束对战", val);
    context.commit("GetJieShuVsData", val);
  },

  //注册弹框
  GetRegisterShow(context, val) {
    context.commit("GetRegisterShow", val);
  },

  //登录弹框
  GetSignInShow(context, val) {
    context.commit("GetSignInShow", val);
  },
   // 保留tab数据
  setTabIndex(context, val) {
    context.commit('SetTabIndex', val);
  },

  //获取 刷新用户信息
  Refresh(context) {
    PersonalInfo().then((res) => {
      context.commit("User", res.data.data);
    });
  },

  //获取全局信息
  getGlobalInfo(context) {
    Info().then((res) => {
      context.commit("setGlobalInfo", res.data.data);
    });
  },
};
