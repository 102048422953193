<template>
  <div class="comp-login">
    <CompPopup @close="$emit('close')">
      <div class="form-box">
        <div class="form-title">{{ !PWCode ? "验证码登录" : "密码登录" }}</div>
        <el-input
          maxlength="11"
          class="clear-input-style form-item-input"
          v-model="SignInForm.mobile"
          placeholder="手机号码"></el-input>
        <el-input
          v-if="PWCode"
          type="password"
          show-password
          class="clear-input-style form-item-input"
          v-model="SignInForm.password"
          placeholder="密码"></el-input>
        <div v-if="!PWCode" class="form-item-input-wrap">
          <el-input
            maxlength="6"
            class="clear-input-style form-item-input"
            v-model="SignInForm.verify"
            placeholder="验证码">
          </el-input>
          <div class="float-send-code flex-c" @click="GetYanZhengMa(2)">
            {{ !VerificationShow ? "发送验证码" : "" }}
            <van-count-down
              style="color: #000; font-size: 0.12rem; width: 0.2rem"
              v-show="VerificationShow"
              :time="time"
              class="CLR"
              format="ss"
              :auto-start="false"
              @finish="finish"
              ref="countDown" />
            {{ !VerificationShow ? "" : "S" }}
          </div>
        </div>
        <el-input
          v-if="!PWCode"
          maxlength="6"
          class="clear-input-style form-item-input"
          v-model="SignInForm.invite"
          placeholder="邀请码"></el-input>
        <div v-if="PWCode" class="form-row3 flex-b">
          <div @click="isRemPassword = !isRemPassword" class="row3-left flex-s">
            <div class="rem-password-checkbox">
              <div v-show="isRemPassword" class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div class="rem-password-text">记住密码</div>
          </div>
          <div @click="forgotPasswordOnClick" class="row3-right">忘记密码</div>
        </div>
        <div class="form-row3 flex-b">
          <div @click="argsStatus1 = !argsStatus1" class="row3-left flex-s">
            <div class="rem-password-checkbox">
              <div v-show="argsStatus1" class="float-gou-icon">
                <div class="bg-img"></div>
              </div>
            </div>
            <div class="rem-password-text">
              登录代表同意<span
                @click="$router.push('/DustProtocol')"
                style="color: #fff"
                >《用户使用条款》</span
              >
            </div>
          </div>
        </div>
        <div @click="PostSignIn" class="form-row4 flex-c long-btn-bg">登录</div>
        <div class="form-row5 flex-c">
          新用户需要验证码登录后可前往我的界面设置密码
        </div>
        <div @click="changeLoginTypeOnClick" class="form-row6 flex-c">
          {{ PWCode ? "验证码登录" : "密码登录" }}
        </div>
      </div>
    </CompPopup>
    <!-- 修改密码 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="showForgotOverlay">
      <CompPopup @close="showForgotOverlay = false">
        <ChangePassword title="重置密码" />
      </CompPopup>
    </van-overlay>
  </div>
</template>

<script>
import {
  PostSendSms,
  SignInByPassword,
  SignInBySms,
  PersonalInfo,
} from "@/network/api.js";
import { mapMutations, mapActions } from "vuex";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      PWCode: false,
      argsStatus1: false,
      argsStatus2: false,
      VerificationShow: false,
      time: 60 * 1000,
      RegisterForm: {
        name: "",
        mobile: "",
        password: "",
        password1: "",
        verify: "",
        invite: "",
      },
      SignInForm: {
        mobile: "",
        password: "",
        verify: "",
        invite: "",
      },
      isRemPassword: false,
      secretKey: "SJAHSKS31312HJASKA21HSJHE4412XCSSAS",
      showForgotOverlay: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      MyInfo: "Refresh",
    }),
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
    init() {
      const array = window.location.search?.split("=");
      console.log(12345, window.location.search);

      if (array.length > 1) {
        this.SignInForm.invite = array[1];
      }
    },
    forgotPasswordOnClick() {
      console.log("忘记了密码");
      this.showForgotOverlay = true;
    },
    //取消登录
    SignInQX() {
      this.EmptySignInForm();
      this.closeFun();
      //   this.GetSignInShow(false);
    },
    //清空登录表单
    EmptySignInForm() {
      // this.SignInForm.email = "";
      this.SignInForm.pass = "";
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        localStorage.setItem("boxUserInfo", JSON.stringify(res.data.data));
        this.GetIndividualsShow(true);
      });
    },
    closeFun() {
      this.$emit("close");
    },
    changeLoginTypeOnClick() {
      this.PWCode = !this.PWCode;
      if (this.PWCode) {
        const savedUsername = localStorage.getItem("savedUsername");
        const savedPassword = localStorage.getItem("savedPassword");
        if (savedUsername && savedPassword) {
          // 解密密码
          const bytes1 = CryptoJS.AES.decrypt(savedUsername, this.secretKey);
          const decryptedUsername = bytes1.toString(CryptoJS.enc.Utf8);
          const bytes2 = CryptoJS.AES.decrypt(savedPassword, this.secretKey);
          const decryptedPassword = bytes2.toString(CryptoJS.enc.Utf8);
          this.SignInForm.mobile = decryptedUsername;
          this.SignInForm.password = decryptedPassword;
          this.isRemPassword = true;
        }
      }
      this.finish();
    },
    //登录操作
    async PostSignIn() {
      if (!this.argsStatus1) {
        this.$message.error(
          "请确认已满18周岁，并同意《用户协议》和《隐私条款》!"
        );
        return;
      }
      let params = {
        mobile: this.SignInForm.mobile,
        pass: this.SignInForm.password,
      };
      let apiFun = SignInByPassword;
      if (this.PWCode) {
        this.SignInForm.verify = "";
        if (this.isRemPassword && this.PWCode) {
          // 将用户名和加密后的密码保存到localStorage
          const encryptedUsername = CryptoJS.AES.encrypt(
            this.SignInForm.mobile,
            this.secretKey
          ).toString();
          const encryptedPassword = CryptoJS.AES.encrypt(
            this.SignInForm.password,
            this.secretKey
          ).toString();
          localStorage.setItem("savedUsername", encryptedUsername);
          localStorage.setItem("savedPassword", encryptedPassword);
        } else {
          // 清除保存的用户名和密码
          localStorage.removeItem("savedUsername");
          localStorage.removeItem("savedPassword");
        }
        // 执行实际的登录操作
        console.log("登录信息:", this.SignInForm);
      } else {
        params = {
          mobile: this.SignInForm.mobile,
          verify: this.SignInForm.verify,
          invite: this.SignInForm.invite,
        };
        apiFun = SignInBySms;
      }
      const res = await apiFun(params);
      if (res?.data?.code == 200) {
        this.$message.success("登录成功");
        localStorage.setItem(
          "token",
          "Bearer" + " " + res?.data?.data?.access_token
        );
        this.GetSignInShow(false);
        this.closeFun();
        this.GetPersonalInfo();
        this.EmptySignInForm();
        this.MyInfo();
      } else {
        this.$message.error(res?.data?.message);
      }
    },
    //发送手机号验证码
    GetYanZhengMa(num) {
      if (this.VerificationShow) {
        return false;
      }

      if (!/^1[3-9]\d{9}$/.test(this.SignInForm.mobile)) {
        this.$message.error("手机号不正确，请重新填写");
        return false;
      }
      if (this.RegisterForm.mobile || this.SignInForm.mobile) {
        this.VerificationShow = true;
        this.$refs.countDown.start();
        PostSendSms(
          this.RegisterForm.mobile || this.SignInForm.mobile,
          num
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    finish() {
      //重置倒计时
      this.VerificationShow = false;
      this.$refs?.countDown?.reset();
    },
  },
};
</script>

<style lang="scss">
.comp-login {
  .form-box {
    width: 2.75rem;
    .form-title {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      margin-bottom: 0.16rem;
    }
    .form-item-input-wrap {
      margin-bottom: 0.16rem;
      width: 100%;
      height: 0.38rem;
      position: relative;
      .float-send-code {
        position: absolute;
        top: 0.04rem;
        right: 0.04rem;
        width: 0.96rem;
        height: 0.3rem;
        font-weight: 500;
        font-size: 0.12rem;
        color: #312f2d;
        @include bgImg("send-code-bg");
      }
    }
    .form-item-input {
      margin-bottom: 0.16rem;
      width: 100%;
      height: 0.38rem;
      background: #413f3e;
    }
    .form-row3 {
      margin-bottom: 0.16rem;
      .row3-left {
        // .rem-password-checkbox {
        //   position: relative;
        //   width: 0.12rem;
        //   height: 0.12rem;
        //   background: #413f3e;
        //   margin-right: 0.08rem;
        //   .float-gou-icon {
        //     position: absolute;
        //     width: 0.16rem;
        //     height: 0.16rem;
        //     left: 0;
        //     bottom: 0;
        //     @include bgImg("check-box-active");
        //   }
        // }
        // .rem-password-text {
        //   color: #999;
        //   font-size: 0.12rem;
        // }
      }
      .row3-right {
        font-size: 0.12rem;
        text-decoration-line: underline;
      }
    }
    .form-row4 {
      width: 100%;
      height: 0.42rem;
      background: red;
      font-weight: 500;
      font-size: 0.18rem;
      color: #312f2d;
      margin-bottom: 0.14rem;
      @include bgImg("long-btn-bg");
    }
    .form-row5 {
      font-size: 0.12rem;
      color: #999999;
      margin-bottom: 0.16rem;
    }
    .form-row6 {
      font-size: 0.12rem;
      color: #ffec45;
      text-decoration: underline;
    }
  }
  // position: relative;
  // .login-out {
  //   // display: flex;
  //   width: 100%;
  //   background: url(../../assets/images/mobile/loginpopupgroup/icon9-new2.png);
  //   background-size: 100% 100%;
  //   position: relative;
  //   padding: 0.1rem 0.1rem 0.12rem 0.22rem;
  //   box-sizing: border-box;

  //   .close-box {
  //     position: absolute;
  //     top: 0.1rem;
  //     right: 0.1rem;
  //     width: 0.12rem;
  //     height: 0.12rem;
  //   }
  //   .reg-header {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     font-size: 0.16rem;
  //     color: #dfdbff;
  //     margin-bottom: 0.2rem;
  //   }
  //   .login-title {
  //     position: absolute;
  //     top: -0.43rem;
  //     left: 10%;
  //     width: 2.5rem;
  //   }
  //   .login-title2 {
  //     position: absolute;
  //     top: -0.4rem;
  //     left: 40%;
  //     width: 1.7rem;
  //   }
  //   .login-title3 {
  //     position: absolute;
  //     top: -0.4rem;
  //     left: 40%;
  //     width: 0.4rem;
  //   }
  //   .DZ-box-mobile {
  //     color: #fff;
  //     width: 100%;
  //     font-size: 0.14rem;
  //     padding: 0 0.66rem;
  //     .el-form-item__content {
  //       line-height: unset;
  //     }
  //     .active-login {
  //       display: flex;
  //       width: 100%;
  //       height: 0.28rem;
  //       margin-bottom: 0.18rem;
  //       align-items: center;
  //       .line {
  //         height: 0.1rem;
  //         width: 0.01rem;
  //         background: #313157;
  //       }
  //       span {
  //         width: 50%;
  //         height: 100%;
  //         color: #909090;
  //         font-size: 0.1rem;
  //         font-weight: 400;
  //         text-align: center;
  //         line-height: 0.28rem;
  //       }
  //       .is-pwd-login {
  //         color: #fff;
  //       }
  //     }
  //     .line-img {
  //       width: 100%;
  //       margin-bottom: 0.26rem;
  //     }
  //     .demo-ruleForm {
  //       .el-form-item {
  //         margin-bottom: 0.1rem;
  //       }
  //       .input-wrap {
  //         position: relative;
  //         width: 100%;
  //         display: flex;
  //         align-items: center;
  //         background: url(../../assets/images/mobile/loginpopupgroup/icon14-new.png)
  //           no-repeat;
  //         background-size: 100% 100%;
  //         .phone-icon {
  //           width: 0.18rem;
  //         }
  //         .el-input__inner {
  //           border: none;
  //           background-color: unset;
  //           color: #eee;
  //           font-size: 0.1rem;
  //           height: 0.23rem;
  //           line-height: 0.23rem;
  //         }
  //         .el-input__inner::-webkit-input-placeholder {
  //           color: rgba(191, 191, 237, 0.5);
  //         }
  //       }
  //     }

  //     .YZM-box {
  //       position: relative;
  //       width: 100%;
  //       .YZM {
  //         padding: 0rem 0.16rem;
  //         border-left: 0.01rem solid #32325d;
  //         position: absolute;
  //         height: 0.13rem;
  //         right: 0rem;
  //         top: 0.05rem;
  //         color: #bfbfed;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         font-size: 0.1rem;
  //       }
  //     }

  //     .args {
  //       width: 100%;
  //       margin-bottom: 0.25rem;
  //       .args-item {
  //         display: flex;
  //         align-items: center;
  //         color: #bfbfed;
  //         font-size: 0.1rem;
  //         white-space: nowrap;
  //         img {
  //           width: 0.1rem;
  //           flex-shrink: 0;
  //           margin-right: 0.06rem;
  //         }
  //       }
  //     }

  //     .btn-box {
  //       width: 100%;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       // margin-top: 0.16rem;

  //       .btn {
  //         width: 0.9rem;
  //         height: 0.26rem;
  //         background: url(../../assets/images/mobile/loginpopupgroup/icon10-new.png)
  //           no-repeat;
  //         background-size: 100% 100%;
  //         color: #d1d1d1;
  //         text-align: center;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         font-size: 0.14rem;
  //       }
  //     }
  //   }
  // }
}
</style>
