<!-- components/GlobalLoading.vue -->
<template>
  <div class="comp-loading">
    <div class="loading-text"></div>
    <div class="loading-line">
      <div :style="`width:${loadingNum}%`" class="loading-line-inner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompLoading",
  data() {
    return {
      loadingNum: 0,
    };
  },
  created() {
    const Timer = setInterval(() => {
      this.loadingNum += 10;
    }, 300);
    setTimeout(() => {
      clearInterval(Timer);
    }, 3000);
    console.log("加载记载");
  },
};
</script>

<style lang="scss">
.comp-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  @include bgImg("loading-bg");
  .loading-text {
    width: 1.19rem;
    height: 0.24rem;
    bottom: 20vh;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    @include bgImg("loading-text");
  }
  .loading-line {
    width: 3.15rem;
    height: 0.03rem;
    left: 50%;
    bottom: 17vh;
    position: absolute;
    border-radius: 0.04rem;
    overflow: hidden;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    .loading-line-inner {
      width: 50%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      @include bgImg("loading-line");
    }
  }
}
</style>
