import originAxios from "axios";
import { Message } from "element-ui";
import common from "../utils/common";
let isShowMsg = true;
let baseURL = process.env.VUE_APP_BASE_URL;
if (process.env.NODE_ENV === "development") {
  baseURL = process.env.VUE_APP_ENV_BASE_URL;
}
if (baseURL !== "https://api.tmskins.com/api") {
  // Message({
  //   message: `test-${baseURL}`,
  //   type: "success",
  // });
}
export default function axios(option) {
  const { noBaseUrl = "" } = option;
  return new Promise((resolve, reject) => {
    const instance = originAxios.create({
      baseURL: noBaseUrl ? "" : baseURL,
      timeout: 10000,
    });
    //拦截
    instance.interceptors.request.use(
      (config) => {
        localStorage.getItem("token") &&
          (config.headers.Authorization = localStorage.getItem("token"));
        // location.href+config.url
        let getUrl = common.qianming({
          data: config.data,
          method: config.method,
          url: config.url,
        }); // 将拼接好的  传给字符串
        // 获取token
        let token = localStorage.getItem("token");
        config.headers = {
          "Cache-Control": "no-cache",
          // 添加到请求头上方
          Authorization: token || "",
          "CsgoAtm-Timestamp": getUrl.timestamp,
          "CsgoAtm-Nonce": getUrl.nonce,
          "CsgoAtm-Sign": getUrl.sign,
        };
        return config;
      },
      (err) => {
        return err;
      }
    );
    // 接口错误拦截
    instance.interceptors.response.use((res) => {
      res.status != 200 && (res.data = { code: 0, message: "网络错误" });
      if (res.data.code == 200 || res.data.code == 500) return res;
      else {
        console.log(33333, res);
        if (isShowMsg && res?.config?.toast !== false) {
          console.log(44444, isShowMsg, res?.config?.toast !== false);
          Message({
            message: res.data.message,
            type: "error",
          });
          isShowMsg = false;
          setTimeout(() => {
            isShowMsg = true;
          }, 500);
        }
      }

      return Promise.reject(res);
    });

    //传入对象进行网络请求
    instance(option)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function Axios(option) {
  return new Promise((resolve, reject) => {
    const instance = originAxios.create({
      baseURL: baseURL,
      timeout: 10000,
    });

    //拦截
    instance.interceptors.request.use(
      (config) => {
        localStorage.getItem("token") &&
          (config.headers.Authorization = localStorage.getItem("token"));
        // let getUrl = common.qianming({
        //   data: config.data,
        //   method: config.method,
        //   url: config.url,
        // }); // 将拼接好的  传给字符串
        // 获取token
        let token = localStorage.getItem("token");
        config.headers = {
          // 添加到请求头上方
          Authorization: token || "",
          // "CsgoAtm-Timestamp": getUrl.timestamp,
          // "CsgoAtm-Nonce": getUrl.nonce,
          // "CsgoAtm-Sign": getUrl.sign,
        };
        return config;
      },
      (err) => {
        return err;
      }
    );

    // 接口错误拦截
    instance.interceptors.response.use((res) => {
      res.status != 200 && (res.data = { code: 0, message: "网络错误" });
      if (res.data.code == 200) return res.data.data;
      else return Promise.reject(res.data);
    });

    //传入对象进行网络请求
    instance(option)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
