<template>
  <div class="comp-header flex-s">
    <div class="comp-header-main flex-b">
      <div class="left-box flex-s">
        <div class="headimg">
          <img :src="user.avatar" alt="" />
        </div>
        <div class="username">
          {{ user.name }}
        </div>
      </div>
      <div class="right-box flex-c">
        <div class="row1">
          <CompCoin imgName="coin-z" :num="user.integral" />
        </div>
        <div @click="$router.push('/BagRecharge')" class="row2">
          <CompCoin :num="user.bean" />
          <div class="float-add"></div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "CompMailHeader",
  computed: {
    ...mapState(["user", "IndividualsShow", "RegisterShow", "SignInShow"]),
  },
  props: {
    backFunc: {
      type: Function,
      default: function () {
        this.$router.go(-1);
      },
    },
  },
  methods: {
    goBackOnClick() {
      this.backFunc();
    },
  },
};
</script>

<style lang="scss">
.comp-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  z-index: 99;
  padding: 0 0.2rem 0 0.12rem;
  @include bgImg("comp-header-bg");
  .comp-header-main {
    width: 100%;
    // position: absolute;
    left: 0.12rem;
    top: 0.06rem;
    .left-box {
      display: flex;
      align-items: center;
      .headimg {
        width: 0.36rem;
        height: 0.36rem;
        margin-right: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @include bgImg("user-headbg");
        img {
          width: 0.34rem;
          height: 0.34rem;
          border-radius: 50%;
        }
      }
    }
    .right-box {
      .row1 {
        margin-right: 0.08rem;
        padding: 0.02rem 0.3rem 0.02rem 0.06rem;
        width: fit-content;
        @include bgImg("home-header-coin-bg");
      }
      .row2 {
        padding: 0.02rem 0.3rem 0.02rem 0.06rem;
        width: fit-content;
        position: relative;
        @include bgImg("home-header-coin-bg");
        .float-add {
          position: absolute;
          right: 0;
          top: 0;
          width: 0.19rem;
          height: 100%;
          @include bgImg("home-header-add-bg");
        }
      }
    }
  }
}
</style>
