<template>
  <div>
    <div class="frombox">
      <div class="fromtitle">{{ title }}</div>
      <div class="frominput">
        <div class="inputbox">
          <div class="inputtitle">输入手机号</div>
          <input
            class="put"
            type="text"
            placeholder="输入手机号"
            v-model="phonevalue"
            :disabled="user?.id ? true : false"
          />
        </div>
        <div class="inputbox">
          <div class="inputtitle">获取验证码</div>
          <input
            class="put"
            type="text"
            placeholder="请输入验证码"
            v-model="passyzm"
          />
          <div class="yzmput" @click="getpassyzm">
            {{ !isCounting ? "获取验证码" : "已发送" }}
            <span v-if="isCounting">{{ countdown }}</span>
          </div>
        </div>
        <div class="inputbox">
          <div class="inputtitle">请设置登录密码</div>
          <input
            class="put"
            type="password"
            placeholder="请输入6~12位，字母+数字的密码组合"
            v-model="newpass"
          />
        </div>
        <div class="inputbox">
          <div class="inputtitle">再次确认登录密码</div>
          <input
            class="put"
            type="password"
            placeholder="再次输入新密码"
            v-model="enterpass"
          />
        </div>
      </div>
      <div class="btnbox">
        <div class="bbtn" @click="dopass">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { PostSendSms, Reset_password } from "@/network/api.js";
var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/; //设置手机号正则表达式
var reg = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/; //密码正则最少6位最多12，包含字母数字
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: "修改密码",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      phonevalue: "",
      passyzm: "", //修改密码验证码
      newpass: "", //新密码
      enterpass: "", //确认新密码

      isCounting: false,
      countdown: 30,
    };
  },
  created() {
    if (this.phone) {
      this.phonevalue = this.phone;
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["GetIndividualsShow", "Leave"]),
    ...mapActions({
      MyInfo: "Refresh",
    }),
    dopass() {
      if (this.passyzm == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
      } else {
        if (!reg.test(this.newpass, this.enterpass)) {
          this.$message({
            message:
              "密码格式错误,最少输入6位最多输入12位密码,必须包含字母数字",
            type: "error",
          });
        } else {
          if (this.newpass != this.enterpass) {
            this.$message({
              message: "两次密码输入错误",
              type: "error",
            });
          } else {
            Reset_password(this.phonevalue, this.enterpass, this.passyzm).then(
              (res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });

                  setTimeout(() => {
                    this.loginOut();
                  }, 1000);
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              }
            );
          }
        }
      }
    },
    getpassyzm() {
      //获取修改密码验证码
      if (this.phonevalue) {
        if (!myreg.test(this.phonevalue)) {
          //验证手机号格式是否正确
          this.$message({
            message: "手机号格式错误",
            type: "error",
          });
        } else {
          if (this.isCounting) {
            this.$message({
              message: "点的太快啦，请等一下",
              type: "error",
            });
            return;
          } // 防止重复发送
          this.isCounting = true;
          this.countdown = 30;
          const interval = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
              clearInterval(interval);
              this.isCounting = false;
            }
          }, 1000);
          PostSendSms(this.phonevalue, 3).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        }
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    //退出
    loginOut() {
      this.GetIndividualsShow(false);
      this.Leave();
      this.$router.push("/Home");
    },
  },
};
</script>

<style lang="scss" scoped>
.frombox {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  .fromtitle {
    text-align: center;
    font-weight: 500;
    font-size: 0.18rem;
    color: #ffffff;
    margin-bottom: 0.12rem;
  }
  .frominput {
    width: 2.75rem;
    margin: 0 auto;
    .inputbox {
      width: 100%;
      margin-bottom: 0.16rem;
      position: relative;
      .inputtitle {
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
        margin-bottom: 0.08rem;
      }
      .put {
        width: 100%;
        height: 0.38rem;
        background: #413f3e;
        text-decoration: none;
        border: 0;
        font-weight: 400;
        font-size: 0.14rem;
        color: #999999;
        text-indent: 0.12rem;
      }
      .putc {
        text-indent: unset;
        text-align: center;
        line-height: 0.38rem;
      }
      .yzmput {
        position: absolute;
        width: 0.96rem;
        height: 0.3rem;
        right: 0.05rem;
        top: 0.255rem;
        background: url("../../assets/newImages/send-code-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.12rem;
        color: #312f2d;
      }
    }
    .tipstext {
      font-weight: 400;
      font-size: 0.12rem;
      color: #999999;
    }
    .textc {
      display: flex;
      justify-content: center;
    }
    .textd {
      text-decoration: underline;
    }
    .imgsbox {
      width: 100%;
      display: flex;
      height: 0.74rem;
      background: #040404;
      overflow-x: auto;
      .headimg {
        display: flex;
        justify-content: center;
        align-items: center;
        .l_imgbox {
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 50%;
          margin: 0 0.06rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .acimgbox {
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 50%;
          margin: 0 0.06rem;
          border: 0.02rem solid #ffec45;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 0.46rem;
            height: 0.46rem;
            border-radius: 50%;
          }
        }
      }
    }
    .setbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.45rem;
      p {
        width: 40%;
        text-align: right;
      }
      .setbut {
        width: 50%;
        .butbg1 {
          width: 0.96rem;
          height: 0.2rem;
          @include bgImg("seat-not-ready-icon");
          position: relative;
          .buttext {
            position: absolute;
            width: 0.28rem;
            height: 0.28rem;
            background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25),
              inset -1px -1px 1px 0px rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0px #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 550;
            font-size: 0.12rem;
            color: #151413;
            top: -0.03rem;
            right: 0.05rem;
          }
        }
        .butbg2 {
          width: 0.96rem;
          height: 0.2rem;
          background: #413f3e;
          position: relative;
          .buttext {
            position: absolute;
            width: 0.28rem;
            height: 0.28rem;
            background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
            box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25),
              inset -1px -1px 1px 0px rgba(0, 0, 0, 0.3),
              inset 1px 1px 1px 0px #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 550;
            font-size: 0.12rem;
            color: #151413;
            top: -0.03rem;
            left: 0.05rem;
          }
        }
      }
    }
  }
  .btnbox {
    width: 100%;
    margin-top: 0.12rem;
    .btn {
      width: 1.4rem;
      height: 0.42rem;
      background: url("../../assets/newImages/user-sbtn.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #312f2d;
      margin: 0 auto;
      font-family: PingFang SC, PingFang SC;
    }
    .bbtn {
      width: 2.8rem;
      height: 0.42rem;
      background: url("../../assets/newImages/long-btn-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #312f2d;
      margin: 0 auto;
      font-family: PingFang SC, PingFang SC;
    }
  }
  .userheadimg {
    position: absolute;
    width: 0.84rem;
    height: 0.84rem;
    top: -0.6rem;
    right: 0.95rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .usertitle {
    width: 100%;
    height: 0.8rem;
    text-align: center;
    line-height: 1rem;
    font-weight: 500;
    font-size: 0.18rem;
    color: #ffffff;
  }
  .changebox {
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.38rem;
    background: #161514;
    margin: 0 auto 0.3rem;
    .typeitem {
      width: 0.96rem;
      height: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
    }
    .actypeitem {
      background: url("../../assets/newImages/send-code-bg.png") no-repeat;
      background-size: 100% 100%;
      color: #151413;
    }
  }
}
</style>
