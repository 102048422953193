<template>
  <div class="comp-popup">
    <div @click="$emit('close')" class="close-btn-box"></div>
    <div class="header-line"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CompPopup",
  props: {},
};
</script>

<style lang="scss">
.comp-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #161514;
  z-index: 100;
  padding: 0.2rem;
  min-height: 1rem;
  .close-btn-box {
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    top: 0.12rem;
    right: 0.12rem;
    z-index: 10;
    @include bgImg("popup-close");
  }
  .header-line {
    position: absolute;
    left: 0;
    top: -0.03rem;
    width: 100%;
    height: 0px;
    border: 0.015rem solid;
    border-image: linear-gradient(
        90deg,
        rgba(250, 226, 119, 1),
        rgba(147, 212, 72, 1),
        rgba(200, 127, 17, 1),
        rgba(250, 226, 119, 1)
      )
      3 3;
  }
}
</style>
