export default [
  {
    path: "/User",
    name: "User",
    component: () => import("@/views/User/index.vue"),
    redirect: "/Highlight",
    children: [
      {
        path: "/About",
        name: "About",
        component: () => import("@/views/userInfo/index.vue"),
      },
      {
        path: "/Warehouse",
        name: "Warehouse",
        component: () => import("@/views/Warehouse/index.vue"),
      },
      {
        path: "/BagRecord",
        name: "BagRecord",
        component: () => import("@/views/BagRecord/index.vue"),
      },
      {
        path: "/BagRecharge",
        name: "BagRecharge",
        component: () => import("@/views/BagRecharge/index.vue"),
      },
      {
        path: "/Highlight",
        name: "Highlight",
        component: () => import("@/views/Highlight/index.vue"),
      },
      {
        path: "/shareReward",
        name: "shareReward",
        component: () => import("@/views/shareReward/index.vue"),
      },
      {
        path: "/deliverGoods",
        name: "deliverGoods",
        component: () => import("@/views/Warehouse/deliverGoods.vue"),
      },
      {
        path: "/completed",
        name: "completed",
        component: () => import("@/views/Warehouse/completed.vue"),
      },
      {
        path: "/vipActive",
        name: "vipActive",
        component: () => import("@/views/Warehouse/vipActive.vue"),
      },
      {
        path: "/Tipspage",
        name: "Tipspage",
        component: () => import("@/views/Tipspage/index.vue"),
      },
    ],
  },
  {
    //开箱(正常)
    path: "/Open",
    name: "Open",
    component: () => import("@/views/Open/index.vue"),
  },
  {
    path: "/openbox",
    name: "openbox",
    component: () => import("@/views/OpenBox/index.vue"),
  },
  {
    path: "/Recharge",
    name: "Recharge",
    component: () => import("@/views/Recharge/index.vue"),
  },

  {
    path: "/PaymentDetails",
    name: "PaymentDetails",
    component: () => import("@/views/Recharge/PaymentDetails.vue"),
  },
  {
    path: "/UnpackingRules",
    name: "UnpackingRules",
    component: () => import("@/views/UnpackingRules/index.vue"),
  },
  {
    path: "/Snatch",
    name: "Snatch",
    component: () => import("@/views/UnpackingRules/Snatch.vue"),
  },
  {
    path: "/Explosion",
    name: "Explosion",
    component: () => import("@/views/UnpackingRules/Explosion.vue"),
  },

  {
    path: "/CreatingRooms",
    name: "CreatingRooms",
    component: () => import("@/views/CreatingRooms/index.vue"),
  },
  {
    path: "/UserAgreement",
    name: "UserAgreement",
    component: () => import("@/views/UnpackingRules/UserAgreement.vue"),
  },

  {
    path: "/Privacy",
    name: "Privacy",
    component: () => import("@/views/UnpackingRules/Privacy.vue"),
  },
  {
    path: "/RushPurchase",
    name: "RushPurchase",
    component: () => import("@/views/RushPurchase/index.vue"),
  },
  {
    path: "/PrizeDetails",
    name: "PrizeDetails",
    component: () => import("@/views/RushPurchase/PrizeDetails.vue"),
  },
  // {
  //   path: "/CreateAConfrontation",
  //   name: "CreateAConfrontation",
  //   component: () => import("@/views/BoxFight/CreateAConfrontation.vue"),
  // },
  {
    path: "/vsbox",
    name: "vsbox",
    component: () => import("@/views/Battle/VsBox.vue"),
  },
  // {
  //   path: "/GameHistory",
  //   name: "GameHistory",
  //   component: () => import("@/views/BoxFight/GameHistory.vue"),
  // },
  {
    path: "/Create",
    name: "Create",
    component: () => import("@/views/Battle/CreateAConfrontation.vue"),
  },
  {
    path: "/BattleVsbox",
    name: "BattleVsbox",
    component: () => import("@/views/Battle/VsBox.vue"),
  },
  {
    path: "/BattleHistory",
    name: "BattleHistory",
    component: () => import("@/views/Battle/GameHistory.vue"),
  },
  {
    path: "/JewelryMall",
    name: "JewelryMall",
    component: () => import("@/views/JewelryMall/index.vue"),
  },
  {
    path: "/Datapage",
    name: "Datapage",
    component: () => import("../components/common/PublicFooter/Datapage.vue"),
  },
];
